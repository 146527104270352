import React, { createContext, useCallback, useState, useContext } from 'react'
import { jwtDecode } from 'jwt-decode'

import api, { blobAPI, fileAPI } from '../services/api'
import { remove, check } from '../services/session'
import socket from '../services/socket'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(useCallback(() => {
    const token = window.localStorage.getItem('#ampliato:token')
    if (token) {
      const user = window.localStorage.getItem('#ampliato:user')
      const parsedUser = JSON.parse(user)
      check({ _id: parsedUser._id, token })
        .then((response) => {
          if (!response.data.check) {
            remove({ _id: parsedUser._id, token })
            window.localStorage.clear()
            socket.disconnect()
            return {}
          }
        })
      const currentTime = new Date().getTime() / 1000
      if (currentTime > jwtDecode(token).exp) {
        const token = window.localStorage.getItem('#ampliato:token')
        const user = window.localStorage.getItem('#ampliato:user')
        const parsedUser = JSON.parse(user)
        api.post('session/delete', { userId: parsedUser._id, token })
        remove({ _id: parsedUser._id, token })
        window.localStorage.clear()
        socket.disconnect()
        return {}
      }
      api.defaults.headers.authorization = `Bearer ${token}`
      fileAPI.defaults.headers.authorization = `Bearer ${token}`
      blobAPI.defaults.headers.authorization = `Bearer ${token}`
      // setNotifications({ userId: parsedUser._id, token })
      socket.auth.authorization = token
      socket.connect()
      return { user: parsedUser, token }
    }
    return {}
  }), [api, fileAPI, blobAPI, socket])

  const singIn = useCallback(async ({ username, password }) => {
    const response = await api.post('session', { username, password })
    if (response.data.status === 'error') { throw response.data.message }
    const token = response.data.user.session

    const user = response.data.user
    window.localStorage.setItem('#ampliato:token', token)
    window.localStorage.setItem('#ampliato:user', JSON.stringify(user))
    window.localStorage.setItem('#ampliato:sidebar', false)
    api.defaults.headers.authorization = `Bearer ${token}`
    fileAPI.defaults.headers.authorization = `Bearer ${token}`
    blobAPI.defaults.headers.authorization = `Bearer ${token}`
    // setNotifications({ userId: user._id, token })
    socket.auth.authorization = token
    socket.connect()
    setData({ user })
  }, [api, fileAPI, blobAPI, socket])

  const signOut = useCallback(() => {
    const token = window.localStorage.getItem('#ampliato:token')
    const user = window.localStorage.getItem('#ampliato:user')
    const parsedUser = JSON.parse(user)
    api.post('session/delete', { userId: parsedUser._id, token })
    remove({ _id: parsedUser._id, token })
    window.localStorage.clear()
    socket.disconnect()
    setData({})
  }, [api, socket, window.localStorage])

  return (<AuthContext.Provider value={{ user: data.user, token: data.token, singIn, signOut }}>{children}</AuthContext.Provider>)
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) { throw new Error('useAuth só pode ser usando dentro do AuthProvider') }
  return context
}
