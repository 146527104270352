const firebaseConfig = {}

export default {
  development: {
    socket: 'http://localhost:5555/',
    api: 'http://localhost:5555/v1/',
    blob: 'http://localhost:5555/v1/static/storage/',
    token: 'jgVIaMVQa1oKQ3N8p1eDX3E4nTsfZdovqVFbg5Pz37FzChskuCiiKw0WLX0iTEE'
  },
  test: {
    socket: 'https://api.teste.agionfield.com.br/',
    api: 'https://api.teste.agionfield.com.br/v1/',
    blob: 'https://api.teste.agionfield.com.br/v1/static/storage/',
    token: 'jgVIaMVQa1oKQ3N8p1eDX3E4nTsfZdovqVFbg5Pz37FzChskuCiiKw0WLX0iTEE'
  },
  production: {
    socket: 'https://api.ampliato.agiontecnologia.com.br/',
    api: 'https://api.ampliato.agiontecnologia.com.br/v1/',
    blob: 'https://api.ampliato.agiontecnologia.com.br/v1/static/storage/',
    token: 'jgVIaMVQa1oKQ3N8p1eDX3E4nTsfZdovqVFbg5Pz37FzChskuCiiKw0WLX0iTEE'
  }
}

export { firebaseConfig }
