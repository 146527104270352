import React, { Suspense, lazy } from 'react'
import { Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom'
import AuthLayout from './AuthLayout'
// PRIVATE PAGES

import Loading from '../components/Loading'
// import Offline from '../components/Offline'

// PUBLIC PAGES
const SignIn = lazy(() => import('../pages/Auth/SignIn'))
const Recover = lazy(() => import('../pages/Auth/Recover'))
const PasswordChange = lazy(() => import('../pages/Auth/PasswordChange'))

const NotFound = lazy(() => import('../pages/404'))
// PUBLIC PAGES

// PRIVATE PAGES
const Home = lazy(() => import('../pages/Home'))
const Monitor = lazy(() => import('../pages/Monitor'))

const ProjectIndex = lazy(() => import('../pages/Project/Index'))
// const ProjectSumCompany = lazy(() => import('../pages/Project/Index/sumCompany'))
const ProjectForm = lazy(() => import('../pages/Project/Form'))
const ProjectView = lazy(() => import('../pages/Project/View'))

const BudgetIndex = lazy(() => import('../pages/Budget/Index'))
const BudgetForm = lazy(() => import('../pages/Budget/Form'))
const BudgetView = lazy(() => import('../pages/Budget/View'))
const BudgetChat = lazy(() => import('../pages/Budget/Chat'))

const BillCompaniesIndex = lazy(() => import('../pages/Bill/Companies/Index'))
const BillCompaniesIndexActive = lazy(() => import('../pages/Bill/Companies/IndexActive'))
const BillCompaniesView = lazy(() => import('../pages/Bill/Companies/View'))

const ForcePasswordChange = lazy(() => import('../pages/Users/ForcePasswordChange'))
const UserTransfer = lazy(() => import('../pages/Users/UserTransfer'))

const ClientIndex = lazy(() => import('../pages/Client/Index'))
const ClientForm = lazy(() => import('../pages/Client/Form'))
const ClientView = lazy(() => import('../pages/Client/View'))

const ManagerIndex = lazy(() => import('../pages/Users/Index'))
const ManagerForm = lazy(() => import('../pages/Users/Form'))
const ManagerView = lazy(() => import('../pages/Users/View'))

const CompanyIndex = lazy(() => import('../pages/Company/Index'))
const CompanyForm = lazy(() => import('../pages/Company/Form'))
const CompanyView = lazy(() => import('../pages/Company/View'))

const HelpIndex = lazy(() => import('../pages/Help'))
const HelpForm = lazy(() => import('../pages/Help/form'))
const HelpView = lazy(() => import('../pages/Help/view'))

const OmbudsmanForm = lazy(() => import('../pages/Contact/ombudsman'))
const ComplimentForm = lazy(() => import('../pages/Contact/compliment'))

const ContactIndex = lazy(() => import('../pages/Contact'))
const ContactIndexAll = lazy(() => import('../pages/Contact/indexAll'))
const ContactView = lazy(() => import('../pages/Contact/view'))

const LocalForm = lazy(() => import('../pages/Local/Form'))
const LocalView = lazy(() => import('../pages/Local/View'))

const DashboardIndex = lazy(() => import('../pages/Dashboard/Index'))

const AuditIndex = lazy(() => import('../pages/Audit/Index'))
const AuditView = lazy(() => import('../pages/Audit/View'))

const MyRoutes = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route element={<AuthLayout login />}>
        <Route index element={<SignIn />} />
        <Route path='*' component={NotFound} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route path='/recuperar-senha' exact element={<Recover />} />
      </Route>

      <Route element={<AuthLayout isPrivate sideBar={false} type='all' />}>
        <Route path='/monitor' exact element={<Monitor />} />
      </Route>

      <Route element={<AuthLayout isPrivate type='all' />}>

        <Route path='/projeto/novo' exact element={<ProjectForm />} />
        <Route path='/projeto/:protocol/editar' exact element={<ProjectForm />} />

        <Route path='/projetos' exact element={<ProjectIndex />} />
        <Route path='/projeto/:protocol' exact element={<ProjectView />} />

        <Route path='/inicio' exact element={<Home />} />
        <Route path='/trocar/senha' exact element={<PasswordChange />} />

        {/*  */}

        <Route path='/orcamento/:protocol' exact element={<BudgetView />} />
        <Route path='/orcamento/:protocol/chat' exact element={<BudgetChat />} />
        <Route path='/orcamentos' exact element={<BudgetIndex />} />

        <Route path='/dashboard' exact element={<DashboardIndex />} />

        <Route path='/auditoria' exact element={<AuditIndex />} />
        <Route path='/auditoria/:_id' exact element={<AuditView />} />

        <Route path='/clientes' exact element={<ClientIndex />} />
        <Route path='/cliente/novo' exact element={<ClientForm />} />
        <Route path='/cliente/:_id/editar' exact element={<ClientForm />} />
        <Route path='/cliente/:_id' exact element={<ClientView />} />

        <Route path='/usuarios' exact element={<ManagerIndex />} />
        <Route path='/usuario/novo' exact element={<ManagerForm />} />
        <Route path='/usuario/:_id/editar' exact element={<ManagerForm />} />
        <Route path='/usuario/:_id' exact element={<ManagerView />} />

        <Route path='/empresas/' exact element={<CompanyIndex />} />
        <Route path='/empresas/fatura/:protocol' exact element={<BillCompaniesView />} />
        <Route path='/empresas/faturas' exact element={<BillCompaniesIndexActive />} />
        <Route path='/empresas/faturas/todas' exact element={<BillCompaniesIndex />} />

        <Route path='/empresa/nova' exact element={<CompanyForm />} />
        <Route path='/empresa/:_id/editar' exact element={<CompanyForm />} />
        <Route path='/empresa/:_id' exact element={<CompanyView />} />

        <Route path='/local/:companyId/novo' exact element={<LocalForm />} />
        <Route path='/local/:_id/editar' exact element={<LocalForm />} />
        <Route path='/local/:_id' exact element={<LocalView />} />

        <Route path='/force/senha' exact element={<ForcePasswordChange />} />
        <Route path='/transferir' exact element={<UserTransfer />} />
        <Route path='/transferir/cliente' exact element={<UserTransfer />} />

        <Route path='/ajuda/lista' exact element={<HelpIndex />} />
        <Route path='/ajuda/novo' exact element={<HelpForm />} />
        <Route path='/ajuda/:_id/editar' exact element={<HelpForm />} />
        <Route path='/ajuda/:_id' exact element={<HelpView />} />

        <Route path='/fale-conosco/lista' exact element={<ContactIndex />} />
        <Route path='/fale-conosco/lista/todos' exact element={<ContactIndexAll />} />
        <Route path='/fale-conosco/:_id' exact element={<ContactView />} />

        <Route path='/ouvidoria/novo' exact element={<OmbudsmanForm />} />
        <Route path='/elogio/novo' exact element={<ComplimentForm />} />

        <Route path='/orcamento/novo' exact element={<BudgetForm />} />
        <Route path='/orcamento/:protocol/editar' exact element={<BudgetForm />} />
      </Route>
    </Routes>
  </Suspense>
)

const router = createBrowserRouter([
  { path: '*', Component: MyRoutes }
])

const AppRoutes = () => (
  <RouterProvider router={router} />
)

export default AppRoutes
