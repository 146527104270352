import api from '../api'

const check = async ({ _id, token }) => {
  return await api.post('/session/check', { userId: _id, token })
}

const remove = async ({ _id, token }) => {
  return await api.post('/session/delete', { userId: _id, token })
}

export { check, remove }
