import React from 'react'
import pngLogo from '../../assets/images/logo.png'

const Logo = ({ style, color = null }) => {
  return (
    <img src={pngLogo} alt='logo' style={style} />
  )
}

export default Logo
