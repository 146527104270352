import React from 'react'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { NavLink } from 'react-router-dom'

const SideListItem = ({ visible = true, name, to, Icon, disabled = false, closeIf, warn }) => {
  return (
    <>
      {visible &&
        <ListItemButton
          selected={window.location.pathname === to}
          sx={{
            p: (theme) => theme.spacing(0.8, 0.8, 0.8, 1),
            backgroundColor: (theme) => warn && '#303030',
            // color: (theme) => warn && theme.palette.primary.contrastText,
            borderRadius: 2,
            mx: 1,
            '&.Mui-selected': {
              backgroundColor: (theme) => theme.palette.primary.light,
              color: (theme) => theme.palette.primary.contrastText,
              '& svg': {
                color: (theme) => theme.palette.primary.contrastText
              }
            }
          }}
          component={NavLink}
          disabled={disabled}
          to={to}
          onClick={() => {
            if (closeIf && !closeIf[1]) {
              closeIf[0]()
            }
          }}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText sx={{ whiteSpace: 'pre' }} primary={name} />
        </ListItemButton>}
    </>
  )
}

export default SideListItem
