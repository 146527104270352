import React from 'react'

import { StyledEngineProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import createCache from '@emotion/cache'
import moment from 'moment-timezone'
import 'moment/locale/pt-br'

import AppProvider from './hooks'
import Routes from './routes'

moment.updateLocale('pt-br')
const cache = createCache({
  key: 'css',
  prepend: true
})

const App = () => (
  <CacheProvider value={cache}>
    <StyledEngineProvider injectFirst>
      <AppProvider>
        <CssBaseline enableColorScheme />
        <Routes />
      </AppProvider>
    </StyledEngineProvider>
  </CacheProvider>
)

export default App
