import { makeStyles } from 'tss-react/mui'
import { colors } from '../../theme'

const drawerWidth = 240
const appBarheight = 65

const useStyles = makeStyles()((theme) => ({
  appBar: {
    width: '100%',
    justifyContent: 'center',
    height: appBarheight,
    background: colors.primary,
    color: colors.secondary,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarDark: {
    width: '100%',
    justifyContent: 'center',
    height: appBarheight,
    background: colors.secondary,
    color: colors.primary,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    borderRadius: '0px 0px 0px 5px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButtonDark: {
    marginRight: theme.spacing(3)
  },
  menuButton: {
    marginRight: theme.spacing(3)
  },
  drawerOpen: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(0.5),
    border: 0,
    maxHeight: '100vh',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    background: '#fff',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerOpenDark: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(0.5),
    border: 0,
    maxHeight: '100vh',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    background: '#121212',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7)
    }
  }
}))

export default useStyles
