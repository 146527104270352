import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { checkUserType } from '../services/userType'
import Navigation from '../navigation'
import { useAuth } from '../hooks'

const AuthLayout = ({ type = 'all', isPrivate = false, sideBar = true, login = false, superOnly }) => {
  const { user } = useAuth()

  if (superOnly && !user?.super) {
    return <Navigate to={isPrivate ? '/' : '/inicio'} replace />
  }

  if (isPrivate && !!user) {
    if (checkUserType(type, user?.type)) {
      return <Navigation sideBar={sideBar}><Outlet /></Navigation>
    }
    return <Navigate to={isPrivate ? '/' : '/inicio'} replace />
  }

  if (isPrivate === !!user) {
    return <Outlet />
  }

  if (isPrivate !== !!user) {
    if (login) {
      return <Navigate to='/inicio' replace />
    }
    if (isPrivate) {
      return <Navigate to={isPrivate ? '/' : '/inicio'} replace />
    } else {
      return <Outlet />
    }
  }
}

export default AuthLayout
