const userNavigationTypes = {
  all: ['MAN', 'FIN', 'PRO', 'MAR', 'MON', 'EST'],
  manager: ['MAN'],
  financeiro: ['FIN'],
  projetista: ['PRO'],
  marcenaria: ['MAR'],
  montagem: ['MON'],
  authorizer: ['MAN', 'FIN'],

  estoque: ['EST'],

  // TODO = remover
  technician: ['TEC', 'PRO'],
  analyst: ['ANA'],
  leader: ['LID'],
  provider: ['PRO'],
  anaPro: ['ANA', 'PRO'],
  manPro: ['PRO', 'MAN'],
  anaTecLiAut: ['ANA', 'TEC', 'PRO', 'LID', 'AUT'],
  anaTecLi: ['ANA', 'TEC', 'PRO', 'LID'],
  manLiAut: ['MAN', 'LID', 'AUT'],
  manLiAna: ['MAN', 'LID', 'ANA'],
  client: ['ANA', 'LID', 'AUT'],
  authLid: ['AUT', 'LID'],
  manAut: ['MAN', 'AUT'],
  manAna: ['ANA', 'MAN'],
  anaTec: ['ANA', 'TEC', 'PRO'],
  anaTecOnly: ['ANA', 'TEC'],
  manTec: ['MAN', 'TEC', 'PRO'],
  manLi: ['MAN', 'LID'],
  super: ['SUPER']
  // TODO = remover
}

const checkUserType = (routeTypes, userTypes) => {
  if (userNavigationTypes[routeTypes].indexOf(userTypes) > -1) { return true }
  return false
}

const userArrayTypes = [
  ['MAN', 'Gerente'],
  ['FIN', 'Financeiro'],
  ['PRO', 'Projetista'],
  ['MAR', 'Marcenaria'],
  ['MON', 'Montagem'],
  ['EST', 'Estoque']
]

const userTypes = {
  MAN: 'Gerente',
  FIN: 'Financeiro',
  PRO: 'Projetista',
  MAR: 'Marcenaria',
  MON: 'Montagem',
  EST: 'Estoque'
}

const userTypesChat = {
  MAN: 'Gerente',
  FIN: 'Financeiro',
  PRO: 'Projetista',
  MAR: 'Marcenaria',
  MON: 'Montagem',
  EST: 'Estoque'
}

const userOsMessage = {
  MAN: 'Projeto em progresso',
  FIN: 'Projeto em progresso',
  PRO: 'Projeto em progresso',
  MAR: 'Projeto em progresso',
  MON: 'Projeto em progresso',
  EST: 'Projeto em progresso'
}

export {
  checkUserType,
  userArrayTypes,
  userTypes,
  userTypesChat,
  userOsMessage
}
