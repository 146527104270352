import React, { useState, useCallback } from 'react'
import { Avatar, Drawer as MuiDrawer, AppBar as MuiAppBar, Toolbar, Typography, Box, IconButton, List, Link, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material'
import { Home, UserCheck, Monitor, PieChart, Settings, Plus, User, Users, AtSign, Sun, Moon, Box as BoxIcon, LogOut, Key, AlertTriangle, HelpCircle, Menu as MenuIcon, MoreVertical, ChevronLeft, X } from 'react-feather'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import useMediaQuery from '@mui/material/useMediaQuery'
import { NavLink, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'

import { userTypes } from '../services/userType'
import { useAuth, useTheme } from '../hooks'

import SideListItem from '../components/SideListItem'
import Logo from '../components/Logo'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const Navigation = ({ sideBar, children }) => {
  const { handleDark, dark } = useTheme()
  const { signOut, user } = useAuth()
  const navigate = useNavigate()

  const smScreen = useMediaQuery(theme => theme.breakpoints.up('sm'))

  const signOutFunction = () => { signOut() }

  const [open, setOpen] = useState(() => {
    const open = window.localStorage.getItem('#ampliato:sidebar')
    return open === 'true'
  })

  const handleDrawer = useCallback(() => {
    window.localStorage.setItem('#ampliato:sidebar', `${!open}`)
    setOpen(!open)
  }, [open])

  return (
    <Box sx={{ display: 'flex', height: '100vh' }} key='home'>
      <AppBar enableColorOnDark position='fixed' open={open} elevation={0} color={dark ? 'primary' : 'primary'}>
        <Toolbar>
          {!open &&
            <IconButton
              aria-label='abrir sidebar'
              onClick={handleDrawer}
              color={dark ? 'secondary' : 'secondary'}
              edge='start'
              sx={{ mr: 2 }}
            >
              <MenuIcon size={25} />
            </IconButton>}
          <IconButton edge='start' color={dark ? 'secondary' : 'secondary'} aria-label='voltar uma pagina' onClick={() => navigate(-1)}>
            <ChevronLeft size={25} />
          </IconButton>

          <Typography component='h1' variant='h6' color='inherit' noWrap>
            <Link noWrap color='inherit' component={NavLink} to='/inicio'>
              <Logo style={{ height: 64 }} />
            </Link>
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          {(smScreen && sideBar) &&
            <>
              <Avatar sx={{ mr: 1, p: 0.5 }} alt={user.name} src={`https://api.dicebear.com/5.x/initials/svg?seed=${user.name}`} />
              <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, m: 0 }}>
                <Typography variant='overline' sx={{ p: 0, m: 0 }}>
                  {user.name}
                </Typography>
                <Typography variant='caption' sx={{ p: 0, mt: -1 }}>
                  {userTypes[user.type]}
                  {user.company ? ` - ${user.company.name}` : ''}
                </Typography>
              </Box>
            </>}

          <PopupState variant='popover' popupId='popup-menu'>
            {(popupState) => (
              <>
                <IconButton color='inherit' aria-label='Sair' {...bindTrigger(popupState)}>
                  <MoreVertical size={15} />
                </IconButton>
                <Menu onClose={() => popupState.close()} {...bindMenu(popupState)}>
                  <MenuItem disabled>
                    <ListItemIcon>
                      <User size={15} />
                    </ListItemIcon>
                    <Typography color='inherit'>
                      {user.name}
                      <br />
                      <Typography color='inherit' variant='caption'>
                        {userTypes[user.type]}
                        {user.company ? ` - ${user.company.name}` : ''}
                      </Typography>
                    </Typography>
                  </MenuItem>

                  <MenuItem disabled>
                    <ListItemIcon>
                      <AtSign size={15} />
                    </ListItemIcon>
                    <Typography color='inherit'>
                      {user.username}
                    </Typography>
                  </MenuItem>

                  {user.super &&
                    <>
                      <Divider />
                      <MenuItem disabled>
                        Super
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          navigate('/fale-conosco/lista')
                        }}
                      >
                        <ListItemIcon>
                          <Settings size={15} />
                        </ListItemIcon>
                        Fale conosco
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          navigate('/ajuda/lista')
                        }}
                      >
                        <ListItemIcon>
                          <HelpCircle size={15} />
                        </ListItemIcon>
                        Ajuda
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          navigate('/auditoria')
                        }}
                      >
                        <ListItemIcon>
                          <AlertTriangle size={15} />
                        </ListItemIcon>
                        Auditoria
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          navigate('/dashboard')
                        }}
                      >
                        <ListItemIcon>
                          <PieChart size={15} />
                        </ListItemIcon>
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.close()
                          navigate('/pre')
                        }}
                      >
                        <ListItemIcon>
                          <UserCheck size={15} />
                        </ListItemIcon>
                        Pré-cadastro
                      </MenuItem>
                    </>}
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      popupState.close()
                      navigate('/trocar/senha')
                    }}
                  >
                    <ListItemIcon>
                      <Key size={15} />
                    </ListItemIcon>
                    Trocar senha
                  </MenuItem>
                  <MenuItem onClick={signOutFunction}>
                    <ListItemIcon>
                      <LogOut size={15} />
                    </ListItemIcon>
                    Sair
                  </MenuItem>
                </Menu>
              </>)}
          </PopupState>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={(smScreen && sideBar) ? 'permanent' : 'temporary'}
        onClose={handleDrawer}
        open={open}
      >

        <DrawerHeader>
          <IconButton onClick={handleDrawer}>
            <X size={25} />
          </IconButton>
        </DrawerHeader>

        <List sx={{ p: 0, mt: 1 }}>
          <SideListItem name='Início' to='/inicio' Icon={Home} />
          <SideListItem name='Todos projeto' to='/projetos' Icon={BoxIcon} />
          {/* <SideListItem name='Novo projeto' to='/projeto/novo' Icon={Plus} /> */}
        </List>

        <Divider sx={{ m: 1 }} />

        <List sx={{ p: 0 }}>
          <SideListItem name='Monitor de projeto' to='/monitor' Icon={Monitor} />
        </List>

        <Divider sx={{ m: 1 }} />

        <List sx={{ p: 0 }}>
          {/* <SideListItem name='Fornecedores' to='/fornecedores' Icon={Users} /> */}
          <SideListItem name='Clientes' to='/clientes' Icon={UserCheck} />
          <SideListItem name='Usuários' to='/usuarios' Icon={Users} />
          {/* <SideListItem name='Faturas fornecedor' to='/fornecedores/faturas/' Icon={DollarSign} />
              <SideListItem name='Faturas empresas' to='/empresas/faturas' Icon={DollarSign} /> */}
        </List>

        <Divider sx={{ m: 1 }} />

        <List sx={{ p: 0 }}>
          <SideListItem name='Novo projeto' to='/projeto/novo' Icon={Plus} warn />
        </List>

        {/* {checkUserType('provider', user?.type) &&
          <>
            <List sx={{ p: 0 }}>
              <SideListItem name='Técnicos' to='/tecnicos' Icon={User} />
              <SideListItem name='Faturas' to='/faturas' Icon={DollarSign} />
            </List>
            <Divider />
            <List sx={{ p: 0 }}>
              <SideListItem name='Monitor de projects' to='/monitoramento' Icon={Monitor} />
              <SideListItem name='Todos projects' to='/projects' Icon={BoxIcon} />
            </List>
            <Divider />
            <List sx={{ p: 0 }}>
              <SideListItem name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
            </List>
          </>}

        {checkUserType('leader', user?.type) &&
          <>
            <List sx={{ p: 0 }}>
              <SideListItem name='Monitor de projects' to='/monitoramento' Icon={Monitor} />
              <SideListItem name='Todos projects' to='/projects' Icon={BoxIcon} />
              <SideListItem name='Novo project' to='/project/novo' Icon={Plus} />
            </List>
            <Divider />
            <List sx={{ p: 0 }}>
              <SideListItem name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
              <SideListItem name='Novo orçamento' to='/orcamento/novo' Icon={FilePlus} />
            </List>
          </>}

        {checkUserType('authorizer', user?.type) &&
          <>
            <List sx={{ p: 0 }}>
              <SideListItem name='Monitor de projects' to='/monitoramento' Icon={Monitor} />
              <SideListItem name='Todos projects' to='/projects' Icon={BoxIcon} />
            </List>
            <Divider />
            <List sx={{ p: 0 }}>
              <SideListItem name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
            </List>
          </>}

        {checkUserType('anaTecOnly', user?.type) &&
          <>
            <List sx={{ p: 0 }}>
              <SideListItem name='Todos projects' to='/projects' Icon={BoxIcon} />
            </List>
            <Divider />
            <List sx={{ p: 0 }}>
              <SideListItem name='Todos orçamentos' to='/orcamentos' Icon={Folder} />
            </List>
          </>} */}

        <Box flexGrow={1} />

        {/* <Divider />
        <List sx={{ padding: 0 }}>
          <SideListItem name='Fale conosco' to='/fale-conosco' Icon={Headphones} closeIf={[handleDrawer, smScreen]} />
        </List>

        <Badge color='secondary' badgeContent='Em breve' overlap='circular' variant={open ? 'string' : 'dot'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <List sx={{ padding: 0 }}>
            <SideListItem name='Central de ajuda' to='/ajuda' Icon={HelpCircle} disabled closeIf={[handleDrawer, smScreen]} />
          </List>
        </Badge> */}
        <Box
          sx={{
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            my: 1
          }}
        >
          <IconButton aria-label='dark-mode' onClick={handleDark}>
            {dark ? <Sun /> : <Moon />}
          </IconButton>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', pt: 8 }}>
        {/* <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', pt: 8 }}> */}
        {children}
      </Box>
    </Box>
  )
}

export default Navigation
