import React, { createContext, useState, useContext, useCallback } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { dark, light } from '../theme'

const ThemeContext = createContext({})

export const AppThemeProvider = ({ children }) => {
  const [data, setData] = useState(useCallback(() => {
    const userDark = window.localStorage.getItem('#ampliato:userDark')

    if (userDark === null) {
      return { theme: dark, dark: true }
    } else {
      return { theme: userDark === 'true' ? dark : light, dark: userDark === 'true' }
    }
  }), [])

  const handleDark = useCallback(() => {
    const userDark = window.localStorage.getItem('#ampliato:userDark')
    if (userDark === null) {
      window.localStorage.setItem('#ampliato:userDark', true)
      setData({ theme: dark, dark: true })
    } else {
      window.localStorage.setItem('#ampliato:userDark', userDark !== 'true')
      setData({ theme: userDark !== 'true' ? dark : light, dark: userDark !== 'true' })
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ theme: data.theme, handleDark, dark: data.dark }}>
      <ThemeProvider theme={data.theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) { throw new Error('useTheme  só pode ser usando dentro do ThemeProvider') }
  return context
}
