import React from 'react'
import { Drawer, AppBar, Toolbar, Typography, Box, Paper, IconButton, List, Link } from '@mui/material'
import { Sun, Moon, Menu as MenuIcon, MoreVertical, ChevronLeft, X } from 'react-feather'
import useMediaQuery from '@mui/material/useMediaQuery'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

import { useTheme } from '../../hooks'
import Logo from '../Logo'

import useStyles from './styles'

const Navigation = () => {
  const { classes } = useStyles()
  const { dark } = useTheme()

  const smScreen = useMediaQuery(theme => theme.breakpoints.up('sm'))
  const appBarheight = '65px'

  const openStorage = window.localStorage.getItem('#ampliato:sidebar')
  const open = openStorage === 'true'

  const SideBarOption = () => (
    <>
      <Box sx={{
        border: 0,
        display: 'flex',
        alignItens: 'center',
        justifyContent: 'flex-end',
        marginBottom: 1,
        height: appBarheight
      }}
      >
        <IconButton aria-label='fechar sidebar'>
          <X size={30} />
        </IconButton>
      </Box>

      <Paper sx={{ marginBottom: 1 }} variant='outlined'>
        <List />
        <List />
        <List />
        <List />
        <List />
        <List />
      </Paper>

      <Paper sx={{ marginBottom: 1 }} variant='outlined'>
        <List />
        <List />
        <List />
      </Paper>

      <Box flexGrow={1} />
      <Box sx={{
        borderRadius: '5px',
        display: 'flex',
        alignItens: 'center',
        justifyContent: 'center'
      }}
      >
        <IconButton aria-label='dark-mode'>
          {dark ? <Sun /> : <Moon />}
        </IconButton>
      </Box>
    </>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar elevation={0} className={clsx(dark ? classes.appBarDark : classes.appBar, open && classes.appBarShift)}>
        <Toolbar sx={{ paddingRight: 0 }}>
          {!open &&
            <IconButton
              edge='start'
              color='inherit'
              aria-label='abrir sidebar'
              className={clsx(dark ? classes.menuButtonDark : classes.menuButton)}
            >
              <MenuIcon size={25} />
            </IconButton>}
          <IconButton edge='start' color='inherit' aria-label='voltar uma pagina'>
            <ChevronLeft size={30} />
          </IconButton>
          <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ paddingTop: 1, flexGrow: 1 }}>
            <Link noWrap color='inherit' component={NavLink} to='/'>
              <Logo style={{ height: 30 }} color={dark ? null : '#303030'} />
            </Link>
          </Typography>
          <IconButton color='inherit' aria-label='Sair'>
            <MoreVertical size={15} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paper: clsx(dark ? classes.drawerOpenDark : classes.drawerOpen, !open && classes.drawerClose) }}
        variant={smScreen ? 'permanent' : 'temporary'}
        open={open}
      >
        <SideBarOption />
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: appBarheight
        }}
      >
        <Paper
          variant='outlined'
          sx={{
            margin: 1,
            padding: 1,
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            borderRadius: '5px'
          }}
        >
          <Typography align='center' color='textSecondary'>
            Loading...
          </Typography>
        </Paper>
      </Box>
    </Box>
  )
}

export default Navigation
